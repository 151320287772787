import React, {Component, useEffect, useState} from 'react';
import HeaderComponent from "../../components/header/header.component";
import headerImage from "../../img/clinical-trials/hero.jpg";
import {hideMask} from "../../components/navigation/navigation.component";
import './clinical-trials.styles.scss'
import firebase from '../../firebase.config';
import TextSectionButtonComponent from "../../components/text-section-button/text-section-button.component";

function ClinicalTrials() {

    const [clinicalTrials, setClinicalTrials] = useState([]);

    const [diseaseTypeArrs, setDiseaseTypeArr] = useState([]);
    const [onLoadArrs, setOnLoadArr] = useState([]);

    var onLoadTrials = [];
    const [selectedDisease, setSelectedDisease] = useState('');

    useEffect( () => {
        const fetchClinicalTrials = async () => {
            const db = firebase.firestore()
            const data = await db.collection("clinicalTrials").get()
            var diseaseArr = [
                "Anus",
                'Bladder',
                'Bones and Joints',
                'Brain and Nervous System',
                'Breast',
                'Breast-Female',
                'Breast-Male',
                'Cancer',
                'Cervix',
                'Colon',
                'Corpus Uteri',
                'Esophagus',
                'Eye and Orbit',
                'Hodgkins Lymphoma',
                'Kaposis sarcoma',
                'Kidney',
                'Larynx',
                'Leukemia, Other',
                'Leukemia, not otherwise specified',
                'Lip, Oral Cavity and Pharynx',
                'Liver',
                'Lung',
                'Lymphoid Leukemia',
                'Multiple',
                'Melanoma, skin',
                'Multiple Myeloma',
                'Mycosis Fungoides',
                'Myeloid and Monocytic Leukemia',
                'Non-Hodgkins Lymphoma',
                'Digestive Organ',
                'Endocrine System',
                'Female Genital',
                'Hematopoietic',
                'Male Genital',
                'Respiratory and Intrathoracic Organs',
                'Skin',
                'Urinary',
                'Ovary',
                'Pancreas',
                'Prostate',
                'Rectum',
                'Small Intestine',
                'Soft Tissue',
                'Stomach',
                'Thyroid',
                'Unknown Sites',
                'Urinary Bladder'
            ];
            setOnLoadArr( data.docs.map( doc => doc.data()) );
            setClinicalTrials( data.docs.map( doc => doc.data()) );
            // Code that would populate the dropdown using logic - only shows categories that exist
            // data.docs.map( doc => {
            //     const diseaseTypeTemp = doc.data().diseaseType;
            //     if(diseaseArr.indexOf(diseaseTypeTemp) === -1) {
            //         diseaseArr.push(diseaseTypeTemp);
            //         console.log('Unique item ' + diseaseTypeTemp);
            //     }
            // })
            setDiseaseTypeArr(diseaseArr)
        }
        fetchClinicalTrials()
    }, [])


    const onCategoryChange = (event) => {
        // Step 1: Get the Disease Type
        const diseaseType = event.target.value;
        console.log(diseaseType)
        if(diseaseType === 'Select') {
            setSelectedDisease('');
        } else {
            setSelectedDisease(diseaseType);
        }
        console.log('category changing to ' + diseaseType)
    }

    const filteredClinicalTrials = clinicalTrials.sort((a, b) => (a.diseaseType < b.diseaseType) ? -1 : 1).filter ( trial =>
            trial.diseaseType.toLowerCase().includes(selectedDisease.toLowerCase())
        )

    return(
        <>
            <main className='page-body clinical-trials-page' onClick={hideMask}>
                <HeaderComponent imageUrl={headerImage}
                                 title="Clinical trials"
                                 isTitleDash={false}
                                 shouldHideHomeButton={false}
                />
                <div className='container my-5'>
                    <div className='row'>
                <TextSectionButtonComponent
                   htmlCopy={`Clinical trials are research studies where interested patients can help discover new
                                    ways to improve treatment and outcomes for themselves and other cancer patients. 
                                    Optum Care Cancer Care provides the community clinical trials offered by 
                                    pharmaceutical industry sponsors and has access to the trials offered by the 
                                    National Cancer Institute. Our continued involvement in clinical research helps us 
                                    find new ways to prevent, diagnose, and treat cancer while managing the symptoms and
                                    reducing the side effects of treatment. <br><br><p>Please contact the clinical trials office for more information. Call
                                                <a href="tel:+17027248787"> 1-702-724-8787</a>, extension #209, and/or email <a href = "mailto: clinicaltrials@optum.com">clinicaltrials@optum.com</a></p>`}
                    customSectionClass={'text-center'}
                />
                    </div>
                </div>
                <div className='container my-5'>
                    <div className='row'>
                        <div className="col-md-6">
                            <p>{filteredClinicalTrials.length} clinical trials</p>
                        </div>
                        <div className="col-md-6">
                            <select onChange={onCategoryChange.bind(this)} className='float-right diseaseTypeDropdown'>
                                <option value="Select">Show: All disease types</option>
                                {diseaseTypeArrs.map( (diseaseType, index) =>  (
                                    <option value={diseaseType} key={diseaseType}>
                                        {diseaseType}
                                    </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <table className="table table-hover hide-mobile">
                        <thead>
                        <tr>
                            <th scope="col">Disease type</th>
                            <th scope="col">Sponsor/Trial Number</th>
                            <th scope="col">Study title</th>
                            <th scope="col"></th>
                        </tr>
                        {
                            filteredClinicalTrials
                                .map( (clinicalTrial, index) =>  (
                                <tr key={index}>
                                    <>
                                        <td>{clinicalTrial.diseaseType}</td>
                                        <td>{clinicalTrial.sponsor}</td>
                                        <td>{ clinicalTrial.protocol ? clinicalTrial.protocol : 'N/A'}</td>
                                        <td style={{width: "200px", overflow: "hidden"}}>
                                            {clinicalTrial.buttonLink !== '#' &&
                                            <a href={clinicalTrial.buttonLink} target="_blank">
                                                <button className='btn btn-primary'>View trial</button>
                                            </a>
                                            }
                                            {clinicalTrial.buttonLink === "#" &&
                                            <p>Please contact the clinical trials office for more information. Call
                                                <a href="tel:+17027248787"> 1-702-724-8787</a>, extension #209, and/or email <a href = "mailto: clinicaltrials@optum.com">clinicaltrials@optum.com</a></p>
                                            }
                                        </td>
                                    </>
                                </tr>
                            ))
                        }
                        </thead>
                    </table>
                    <div className="hide-desktop">
                        {
                            filteredClinicalTrials
                                .map( (clinicalTrial, index) =>  (
                                    <div className={'card p-4 mb-4'}>
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <h4 className={'mb-1'}>Title</h4>
                                                <p className={'mb-0'}>
                                                    { clinicalTrial.protocol ? clinicalTrial.protocol : 'N/A'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <h4 className={'mb-1'}>Disease Type</h4>
                                                <p className={''}>
                                                    {clinicalTrial.diseaseType}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <h4 className={'mb-1'}>Sponsor/Trial number</h4>
                                                <p>
                                                    {clinicalTrial.sponsor}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                {clinicalTrial.buttonLink !== '#' &&
                                                <a href={clinicalTrial.buttonLink} target="_blank">
                                                    <button className='btn btn-primary'>View trial</button>
                                                </a>
                                                }
                                                {clinicalTrial.buttonLink === "#" &&
                                                <p className={'mr-3 mb-0'}>
                                                    Please contact the clinical trials office for more information. Call <a href="tel:+17027248787"> 1-702-724-8787</a>, extension #209, and/or email <a href="mailto:clinicaltrials@optum.com">clinicaltrials@optum.com</a>

                                                </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                        ))
                            }
                    </div>

                    { filteredClinicalTrials.length === 0 &&
                    <div className={'row'}>
                        <h4 className={'mt-3 text-center'}>
                            No results found for { selectedDisease ? selectedDisease : 'N/A'}.
                        </h4>
                    </div>
                    }
                </div>

            </main>
        </>
    );
}

export default ClinicalTrials;
